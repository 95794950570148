import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { switchMap, takeUntil, tap, filter, take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import firebase from 'firebase/compat/app';

interface UserConfig {
  sidebar_isToggled: boolean;
  sidebar_isOpen: boolean;
  sidebar_mode: string;
  items_toggle_get_Detail: boolean;
  dark_mode_activated: boolean;
  stock_control_required_stock: boolean;
  stock_control_required_stock_all: boolean;
  stock_control_show_products: boolean;
  order_supplier_info?: any;
}

@Injectable({
  providedIn: 'root',
})
export class UserDataService {
  // Public properties for components to access
  readonly mode$ = new BehaviorSubject<string>('side');
  readonly isOpen$ = new BehaviorSubject<boolean>(true);
  readonly isToggled$ = new BehaviorSubject<boolean>(false);
  readonly currentUser$ = new BehaviorSubject<any>(undefined);
  readonly userConfig$ = new BehaviorSubject<UserConfig | null>(null);

  private destroy$ = new Subject<void>();

  constructor(
    private afs: AngularFirestore,
    private afAuth: AngularFireAuth
  ) {
    this.listenForUserChanges();

    const config = this.getUserConfig();
    this.userConfig$.next(config);

    const isOpenSavedConf = config.sidebar_isOpen;
    this.isOpen$.next(isOpenSavedConf);

    const istoggledSavedConf = config.sidebar_isToggled;
    this.isToggled$.next(istoggledSavedConf);

    const modeSavedConf = config.sidebar_mode;
    this.mode$.next(modeSavedConf);
  }

  // Public methods to access config
  get userConfig(): UserConfig {
    return this.userConfig$.getValue() || this.getUserConfig();
  }

  getDarkModeState(): boolean {
    return this.userConfig.dark_mode_activated;
  }

  getUserDataById(userId: string): Observable<any> {
    return this.afs.collection('users').doc(userId).valueChanges({ idField: 'id' });
  }

  // Escucha cambios de auth y carga datos del usuario
  private listenForUserChanges() {
    this.afAuth.authState
      .pipe(
        takeUntil(this.destroy$), // Si el servicio se destruye por alguna razón
        switchMap((user) => {
          if (user) {
            // console.log('Usuario autenticado:', user.uid);
            return this.afs
              .doc<any>(`users/${user.uid}`)
              .valueChanges({ idField: 'id' });
          } else {
            // console.log('Usuario deslogueado');
            return []; // Si no hay usuario, retorna vacío
          }
        })
      )
      .subscribe((userData) => {
        // console.log('Datos actualizados:', userData);
        this.setCurrentUser(userData);
      });
  }

  setCurrentUser(user: any): void {
    this.currentUser$.next(user);
  }

  getCurrentUser(): Observable<any> {
    return this.currentUser$.pipe(
      take(1),
      switchMap(user => {
        if (user === undefined) {
          // If user is undefined, get user data
          return this.afAuth.authState.pipe(
            filter((authState: firebase.User | null): authState is firebase.User => authState !== null),
            take(1),
            switchMap(authState => {
              return this.afs
                .doc<any>(`users/${authState.uid}`)
                .valueChanges({ idField: 'id' })
                .pipe(
                  tap(userData => {
                    this.setCurrentUser(userData);
                  })
                );
            })
          );
        }
        return of(user);
      })
    );
  }

  getUserConfig(): UserConfig {
    let config: UserConfig = JSON.parse(
      localStorage.getItem('butaco_user_config') || '{}'
    );
    if (!config.sidebar_isToggled) {
      config = {
        sidebar_isToggled: false,
        sidebar_isOpen: true,
        sidebar_mode: 'side',
        items_toggle_get_Detail: false,
        dark_mode_activated: false,
        stock_control_required_stock: false,
        stock_control_required_stock_all: false,
        stock_control_show_products: false,
      };
    }
    this.userConfig$.next(config);
    return config;
  }

  saveUserConfig(config: UserConfig): void {
    localStorage.setItem('butaco_user_config', JSON.stringify(config));
    this.userConfig$.next(config);
  }

  // Show Products - Items Simple
  setUserConfigToggleGetDetail(itemsToggleGetDetail: boolean): void {
    const config = this.userConfig;
    config.items_toggle_get_Detail = itemsToggleGetDetail;
    this.saveUserConfig(config);
  }

  // DarkMode On-Off
  setUserConfigDarkMode(darkModeState: boolean): void {
    const config = this.userConfig;
    config.dark_mode_activated = darkModeState;
    this.saveUserConfig(config);
  }

  // Show stock control required stock items or not
  changeShowRequiredStockItems(newState: boolean): void {
    const config = this.userConfig;
    config.stock_control_required_stock = newState;
    this.saveUserConfig(config);
  }

  // Show all items or filter by required or not
  changeShowRequiredStockItemsAll(newState: boolean): void {
    const config = this.userConfig;
    config.stock_control_required_stock_all = newState;
    this.saveUserConfig(config);
  }

  changeShowProductsStockItems(newState: boolean): void {
    const config = this.userConfig;
    config.stock_control_show_products = newState;
    this.saveUserConfig(config);
  }

  // ToggleSideNav
  toggle(newState: boolean): void {
    this.isToggled$.next(newState);
    const config = this.userConfig;
    config.sidebar_isToggled = newState;
    this.saveUserConfig(config);
  }

  // Show or not SideNav
  openClose(newState: boolean): void {
    this.isOpen$.next(newState);
    const config = this.userConfig;
    config.sidebar_isOpen = newState;
    this.saveUserConfig(config);
  }

  sidebarMode(newState: string): void {
    this.mode$.next(newState);
    const config = this.userConfig;
    config.sidebar_mode = newState;
    this.saveUserConfig(config);
  }

  saveNewOrderInfo(newOrder: any): void {
    const config = this.userConfig;
    config.order_supplier_info = newOrder;
    this.saveUserConfig(config);
  }
}
